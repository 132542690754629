import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from "react-hook-form";
import React from "react";

export const FormCheckBox = ({ name, control, label, defaultValue }) => {
  return (
    <FormControlLabel
    control={
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field: props }) => (
          <Checkbox
            {...props}
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        )}
      />
    }
    label={label}
  />
  );
};
