import TextField from '@mui/material/TextField';
import { Controller } from "react-hook-form";
import React from "react";

export const FormInputText = ({ name, control, label, rules, ...props }) => {
  return (
    <Controller
    defaultValue={''}
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value } }) => (
      <TextField onChange={onChange} value={value} label={label} {...props} />
    )}
  />
  );
};
