import React, { createRef, useContext, useState } from 'react';
import Turnstile, { useTurnstile } from "react-turnstile";
import axios from 'axios';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as TrappedLogo } from './svg-assets/logo.svg';
import { FormPhoneNumber } from './components/form/FormPhoneNumber';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://trapped.com/">
        Trapped
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function CheckIn() {

  const turnstile = useTurnstile();
  const navigate = useNavigate();
  const params = useParams();
  const location = params.locationId;
  const [loading, setLoading] = useState(false);

  const { handleSubmit, watch, control, setError, formState: { errors } } = useForm();
  const recaptchaRef = createRef();

  const [recaptchaState, setRecaptchaState] = useState({
    loaded: false,
    expired: true,
    token: null
  });

  const onSubmit = async data => {
    if(recaptchaState.expired || !recaptchaState.loaded) {
      return;
    }
    const token = recaptchaState.token;

    data.phoneNumber = data.phoneNumber.replace("\+1", "").replace(/\s/g, '');
    setLoading(true);
    const result = await axios.post(`https://api.trapped.com/location/${location}/checkin`,
      { phoneNumber: data.phoneNumber },
      { headers: { "challenge": token } })
      .catch(err => err.response.data);
    setLoading(false);

    if (result.data.existingMember) {
      navigate("/checkin/verify", { state: { memberId: result.data.id, phoneNumber: data.phoneNumber, locationId: location } });
    } else {
      navigate(`/${location}/new`, { state: { phoneNumber: data.phoneNumber } });
    }
  }
  const onLoad = () => setRecaptchaState({ ...recaptchaState, loaded: true });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <TrappedLogo height={120} width={"90%"} />
        <Typography component="h1" variant="h6">
          Let's start with your phone number...
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormPhoneNumber name="phoneNumber" label="Phone Number" control={control} required fullWidth rules={{ required: "Phone number is required", pattern: { value: /\+1\s?\d\d\d\s?\d\d\d\s?\d\d\d\d/, message: "Valid phone number is required" } }} error={!!errors.phoneNumber} helperText={errors.phoneNumber?.message} />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading || !recaptchaState.loaded || !recaptchaState.token}
          >
            Next
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
          <Turnstile
            sitekey="0x4AAAAAAANCDYnSW8VTRwEh"
            onVerify={(token) => { setRecaptchaState({ ...recaptchaState, token, expired: false })}}
            onLoad={() => setRecaptchaState({ ...recaptchaState, loaded: true })}
            onExpire={ () => setRecaptchaState({ ...recaptchaState, expired: true, token: null})}
          />
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}