import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {ReactComponent as TrappedLogo} from './svg-assets/logo.svg';
import CssBaseline from '@mui/material/CssBaseline';
import {useLocation, Link} from 'react-router-dom';

export default function ThankYou() {
    const location = useLocation();

    let thankyouMessage = "Thank you for signing up as a Trapped member.";
    if(location?.state?.checkIn) {
        thankyouMessage = `Welcome back ${location?.state?.fullName}. Please proceed to check-in.`;
    } 
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                }}
            >

                <TrappedLogo height={120} width={"90%"} />
                <Typography paragraph align='center' variant='h7'>
                    {thankyouMessage}
                </Typography>
                <Typography paragraph align='center'>Enjoy your exclusive member perks</Typography>
                {location?.state?.checkIn && <Typography variant='caption' paragraph align='center' >If {location?.state.fullName} is not your name, click <Link to={`/${location?.state?.locationId}/new`} state={{phoneNumber: location.state.phoneNumber }}>here</Link> to create a new profile</Typography> }
            </Box>
        </Container>
    );
}