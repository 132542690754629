import * as React from 'react';
import DocumentTitle from 'react-document-title';
import ReactDOM from 'react-dom/client';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignUp from './signup'
import ThankYou from './thankyou'
import VerifyOTP from './verify'
import VerifyCheckInOTP from './verifyCheckin';
import CheckIn from './checkin';
import './hide-recaptcha.css';

const NotFound = () =>
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: "#121212",
  }}>
    <Typography variant="h3" sx={{ color: "white" }}>
      404 page not found
    </Typography>
  </Box>

const router = createBrowserRouter([
  {
    path: "/:locationId",
    element: <CheckIn />
  },
  {
    path: "/:locationId/new",
    element: <SignUp />
  },
  {
    path: "/",
    element: <NotFound />
  },
  {
    path: "/thankyou",
    element: <ThankYou />
  },
  {
    path: "/verify",
    element: <VerifyOTP />
  },
  {
    path: "/checkin/verify",
    element: <VerifyCheckInOTP />
  }
]);

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00a651',
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px #121212 inset",
            "-webkit-text-fill-color": "#fff",
            "background-color": "#121212 !important",
            "background-clip": "content-box !important"
          },
        },
      },
    },
  }
});


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <DocumentTitle title='Trapped Membership'>
          <RouterProvider router={router} />
      </DocumentTitle>
    </ThemeProvider>
  </React.StrictMode>
);
