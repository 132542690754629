import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Controller } from "react-hook-form";
import React from "react";

export const FormSelect = ({
    name,
    label,
    control,
    defaultValue,
    children,
    ...props
    }) => {
    const labelId = `${name}-label`;
  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field: {onChange, onBlur, value} }) => (
          <Select labelId={labelId} label={label} onChange={onChange} onBlur={onBlur} value={value}>
            {children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
