import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import VerificationInput from "react-verification-input";
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import {useLocation, useNavigate} from 'react-router-dom';
import { createRef, useState, useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


import axios from 'axios';
import './verify.css';
import { Button } from '@mui/material';

export default function VerifyCheckInOTP() {

    const ref = createRef();
    const location = useLocation();
    const [submitting, setSubmitting] = useState(false);
    const [notVerified, setNotVerified] = useState(false);
    const [value, setValue] = useState("");
    const [countDown, setCountDown] = useState(60);

    useEffect(() => {
        const timer = setInterval(() => {
            if(countDown > 0) {
                setCountDown(countDown - 1);
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [countDown]);
    
    const navigate = useNavigate();

    const onChange = async (e) => {
        setValue(e);
        if(e.length < 6) {
            return;
        }
        const input = ref.current;
        input.disabled = true;
        setSubmitting(true);
        const result = await axios.post(`https://api.trapped.com/location/${location.state.locationId}/checkin/${location.state.memberId}/verify`, {verificationCode: e})
            .catch(err => err.response.data);

        if(result.data.verified === true) {
            navigate('/thankyou', {state: {fullName: result.data.fullName, checkIn: true, locationId: location.state.locationId, phoneNumber: location.state.phoneNumber}});
        } else {
            setNotVerified(true);
            input.disabled = false;
            input.focus();
            setValue("");
            setSubmitting(false);
        }
    }

    const newCode = async () => {
        setCountDown(60);
        const result = await axios.post(`https://api.trapped.com/member/${location.state.memberId}/code`, {})
            .catch(err => err.response.data);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                }}
            >
            <Typography variant='h5'>Enter the verification code</Typography>
            <Divider variant='fullWidth' sx={{margin: 2}} flexItem />
            <Typography paragraph align="center">Let's make sure you have entered your phone number correctly. We've just sent you a text message with a verification code to the phone number {location.state.phoneNumber} </Typography>
            <VerificationInput classNames={{
                character: "character",
            }}
            inputProps={{ autoComplete: "one-time-code" }}
            validChars="0-9"
            autoFocus
            value={value}
            onChange={onChange}
            ref={ref}
            />
            { submitting ? <LinearProgress color="success" sx={{width: "100%", marginTop: 3}} /> : <></>}
            <Divider variant='fullWidth' sx={{margin: 2}} flexItem />
            { notVerified ? <Typography paragraph align="center">Verification code entered was incorrect please try again</Typography> : <></>}
            <Typography paragraph align="center">Didn't receive a code?<Tooltip title={countDown > 0 ? `Receive another code in ${countDown} seconds` : "Click to receive another code"}><span><IconButton onClick={newCode} disabled={countDown > 0}size="small"><RefreshIcon /></IconButton></span></Tooltip></Typography> 
            </Box>
        </Container>
    );
}