import React, { useState, createRef } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as TrappedLogo } from './svg-assets/logo.svg';
import { FormInputText } from './components/form/FormInputText';
import { FormCheckBox } from './components/form/FormCheckBox';
import { FormSelect } from './components/form/FormSelect';
import Turnstile, { useTurnstile } from "react-turnstile";



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://trapped.com/">
        Trapped
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export default function SignUp() {
  const turnstile = useTurnstile();
  const navigate = useNavigate();
  const params = useParams();
  const location = params.locationId;
  const uLocation = useLocation();

  const [loading, setLoading] = useState(false);

  const { handleSubmit, watch, control, setError, formState: { errors }, setValue } = useForm();
  setValue('phoneNumber', `+1${uLocation.state.phoneNumber}`);
  const recaptchaRef = createRef();

  const [recaptchaState, setRecaptchaState] = useState({
    loaded: false,
    expired: true,
    token: null
  });

  const onSubmit = async data => {
    if (recaptchaState.expired || !recaptchaState.loaded) {
      return;
    }
    const token = recaptchaState.token;
    data.firstName = capitalize(data.firstName);
    data.lastName = capitalize(data.lastName);
    data.phoneNumber = data.phoneNumber.replace("\+1", "").replace(/\s/g, '');
    data.postalCode = data.postalCode ? data.postalCode.replace(/-|\s/, '').toUpperCase() : undefined;
    setLoading(true);

    const result = await axios.post(`https://api.trapped.com/location/${location}/member`, data, { headers: { challenge: token } })
      .catch(err => err.response.data);
    setLoading(false);

    if (result.validationErrors) {
      for (let error of result.validationErrors) {
        setError(error.field, { type: 'custom', message: error.message }, true);
      }
    } else if (result.data.existingMember) {
      navigate("/thankyou", { state: { existingMember: true } })
    } else {
      navigate("/verify", { state: { memberId: result.data.id, phoneNumber: data.phoneNumber } });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <TrappedLogo height={120} width={"90%"} />
        <Typography component="h1" variant="h5">
          Membership Sign Up
        </Typography>
        <br />
        <Typography component="p" variant="p" align='center'>
          The phone number provided did not match any existing member. Fill out the form to become a Trapped Member.
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInputText name="firstName" label="First Name" control={control} rules={{ required: "First name is required" }} error={!!errors.firstName} helperText={errors.firstName?.message} fullWidth required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText name="lastName" label="Last Name" control={control} rules={{ required: "Last name is required" }} error={!!errors.lastName} helperText={errors.lastName?.message} required fullWidth />
            </Grid>
            <Grid item xs={12}>
              <FormInputText name="phoneNumber" label="Phone Number" control={control} required fullWidth disabled={true} />
            </Grid>
            <Grid item xs={12}>
              <FormInputText name="email" label="Email" control={control} required fullWidth rules={{ required: "Eamil address is required", pattern: { value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, message: "Valid email address is required" } }} error={!!errors.email} helperText={errors.email?.message} />
            </Grid>
            <Grid item xs={12}>
              <FormInputText name="postalCode" label="Postal Code" fullWidth control={control} rules={{ pattern: { value: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i, message: "Valid postal code is required" } }} error={!!errors.postalCode} helperText={errors.postalCode?.message} />
            </Grid>
            <Grid item xs={12}>
              <FormSelect control={control} label="How did you hear about us?" name="referral" defaultValue={""} fullWidth>
                <MenuItem value="">How did you hear about us?</MenuItem>
                <MenuItem value="google">Google</MenuItem>
                <MenuItem value="social-media">Social Media</MenuItem>
                <MenuItem value="family-friends">Family and Friends</MenuItem>
                <MenuItem value="drove-by">Drove By</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </FormSelect>
            </Grid>
            <Grid item xs={12}>
              <FormCheckBox name="allowMarketingEmail" control={control} defaultValue={false} label="I want to receive theme and event updates via email" />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading || !recaptchaState.loaded}
          >
            Sign Up
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
          <Turnstile
            sitekey="0x4AAAAAAANCDYnSW8VTRwEh"
            onVerify={(token) => { setRecaptchaState({ ...recaptchaState, token, expired: false }) }}
            onLoad={() => setRecaptchaState({ ...recaptchaState, loaded: true })}
            onExpire={() => setRecaptchaState({ ...recaptchaState, expired: true, token: null })}
          />
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}