import { MuiTelInput } from 'mui-tel-input'
import { Controller } from "react-hook-form";
import React, { useState } from "react";

export const FormPhoneNumber = ({ name, control, label, rules, ...props }) => {
  const [state, setState] = useState("");
  const formattingChange = (nativeChange, telChange, info) => {
    if (info.countryCallingCode === null) {
      telChange = "+1" + telChange.substring(1);
    }
    setState(telChange);
    nativeChange(telChange);
  }
  return (
    <div>
      <Controller
        defaultValue={''}
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <MuiTelInput
            defaultCountry="CA"
            onlyCountries={['CA', 'US']}
            label={label}
            onChange={(telChange, info) => formattingChange(onChange, telChange, info)}
            value={value}
            forceCallingCode={true}
            disableDropdown={true}
            {...props} />
        )}
      />
    </div>
  );
};
